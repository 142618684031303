import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModTwoImgCols from "../../../components/Chronology/Modules/ModTwoImgCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ModAnimation from "../../../components/Chronology/Modules/ModAnimation";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDatafactCountry from "../../../components/Chronology/Modules/ModDatafactCountry";
import ModDatafactCCAA from "../../../components/Chronology/Modules/ModDatafactCCAA";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModBuscandoDatos from "../../../components/Chronology/Modules/ModBuscandoDatos";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModReport from "../../../components/Chronology/Modules/ModReport";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";
import { StrongWhite, StrongGold } from "../../../components/Textstyles/Styles";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";
import ModVideo from "../../../components/Chronology/Modules/ModVideo";
import ModFootnote from "../../../components/Chronology/Modules/ModFootnote";
import ModDataEs from "../../../components/Chronology/Modules/ModDataEs";

export const frontmatter = {
  title: "Día 103",
  week: "Semana 15",
  day: "24",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-24",
  path: "/cronologia/semana-15#dia-24-jun/",
};

const Day103 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En los últimos siete días se han diagnosticado en{" "}
          <strong>España</strong> 1.755 casos mediante pruebas PCR. En ese mismo
          periodo, 150 personas han requerido hospitalización (8,5% de los
          diagnósticos), de los que 16 han sido ingresos en la UCI, y se han
          producido 10 fallecimientos.
        </ModText>
        <ModText>
          El BOE ha publicado el{" "}
          <InlineLink link="https://www.boe.es/boe/dias/2020/06/24/pdfs/BOE-A-2020-6621.pdf">
            Real Decreto-ley
          </InlineLink>{" "}
          aprobado por el Consejo de Ministros para impulsar las energías
          renovables y convertir al sector en motor de la recuperación económica
          e industrial tras la crisis del COVID-19, con el objetivo último de
          que España obtenga toda su electricidad de fuentes sostenibles antes
          del año 2050.
        </ModText>
        <ModImage
          src="/images/svg/inversion-ministros-consejo.svg"
          alt="inversion activos energeticos"
        />
      </ContentRight>
    </LayoutDay>
  );
};
export default Day103;
