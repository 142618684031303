import React from "react";
import { Flex, Text, MaxWidth, Box } from "@sqymagma/elements";

export default ({ children, color = "brand04" }) => {
  const bgColorSet = ["brand02", "brand05", "brand06", "brand01"];
  let textColor = bgColorSet.includes(color) ? "text06" : "text01";
  let svgImg = bgColorSet.includes(color)
    ? "/images/anim/logo_ani_white.svg"
    : "/images/anim/logo_ani_black.svg";

  return (
    <Flex width={1} bg={color} py="s">
      <MaxWidth>
        <Flex alignItems="center" justifyItems="start">
          <Box width={["150px", "100px"]} ml={"-10px"}>
            <object
              type="image/svg+xml"
              data={svgImg}
              aria-label="Icono animado covid"
              alt="Icono animado covid"
              width={["90px", "75px"]}
              height={["90px", "75px"]}
            />
          </Box>
          <Text as="h2" textStyle="xl" color={textColor}>
            {children}
          </Text>
        </Flex>
      </MaxWidth>
    </Flex>
  );
};
