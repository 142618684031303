import React from "react"
import { Flex, Text, MaxWidth, Box } from "@sqymagma/elements"
import ModAnimation from "../Modules/ModAnimation"
import { Link } from "gatsby"
import { theme } from "../../../utils/themeGet"

export default () => {
  return (
      <Link to="/guia-desescalada">
    <Flex width={1} bg="ui07" py="l"
        css={`{
            transition: background-color ease-in .3s, color ease-in .4s;
            border-bottom: 1px solid rgba(0,0,0,.3);
            border-top: 1px solid rgba(0,0,0,.3);
            &:hover {
                background-color: ${theme("color.brand04")};
                h2, h4 {
                    color: ${theme("color.ui07")};
                }
            }
        }`}
    >
        <MaxWidth>
            <Flex alignItems="center" justifyItems="space-between" flexDirection={['column','row']}>

            <Flex width={[1, 3/4]} alignItems="center" mb={['s', 0]}>
                <Text as="h2" fontWeight="800" lineHeight="1.05" fontSize={["40px",null,null, null, null,"56px"]} color="brand04" mr="m">Guía de la desescalada</Text>
                <AnimatedArrow/>
            </Flex>

            <Box width={[1, 1/4]}>
                <Text as="h4" textStyle="l" color="ui01" pl="xs">
                Infórmate de lo que puedes hacer o no en tu Fase 
                </Text>
            </Box>

            </Flex>
        </MaxWidth>
    </Flex>
      </Link>
  )
}

const AnimatedArrow = _ => (
    <Flex width="xxl" mt="xs" display={['none', null, 'flex']}
    css={`{flex: auto}`}>
        <Box maxWidth="150px">
        <ModAnimation svg='/images/anim/flecha_ani_00.svg'/>
        </Box>
    </Flex>
)