import React from "react";
import LayoutWeek from "../../../components/Chronology/LayoutWeek";
import SecWeekly, {
  SecWeeklyColumn,
} from "../../../components/Chronology/Modules/SecWeekly";
import ModMegaBanner from "../../../components/Chronology/Modules/ModMegaBanner";
import ModAlertBanner from "../../../components/Chronology/Modules/ModAlertBanner";
import ModDesescalada from "../../../components/Chronology/Modules/ModDesescalada";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import { StrongWhite, StrongGold } from "../../../components/Textstyles/Styles";
import ModGraphFull from "../../../components/Chronology/Modules/ModGraphFull";

import Day99 from "./dia-20-jun";
import Day100 from "./dia-21-jun";
import Day101 from "./dia-22-jun";
import Day102 from "./dia-23-jun";
import Day103 from "./dia-24-jun";
import Day104 from "./dia-25-jun";
import Day105 from "./dia-26-jun";

const Week = (props) => {
  return (
    <LayoutWeek {...props}>
      <ModAlertBanner color="brand02">
        Brasil registra 56.000 fallecidos por coronavirus
      </ModAlertBanner>
      <ModDesescalada />
      <Day105 {...props} />
      <Day104 {...props} />
      <Day103 {...props} />
      <Day102 {...props} />
      <Day101 {...props} />
      <ModMegaBanner bg="brand05">
        Hoy finaliza el estado de alarma y se inicia la Nueva Normalidad.
      </ModMegaBanner>

      <Day100 {...props} />
      <Day99 {...props} />
    </LayoutWeek>
  );
};

export default Week;
