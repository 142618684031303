import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import { Flex, Box } from "@sqymagma/elements";

export const frontmatter = {
  title: "Día 100",
  week: "Semana 15",
  day: "21",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-21",
  path: "/cronologia/semana-15#dia-21-jun/",
};

const Day100 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En los últimos siete días se han diagnosticado en{" "}
          <strong>España</strong> 1.668 casos mediante pruebas PCR. En ese mismo
          periodo, 99 personas han requerido hospitalización (5,9% de los
          diagnósticos), de los que cinco han sido ingresos en la UCI, y se han
          producido 29 fallecimientos.
        </ModText>
        <ModCovidHighlight>
          Ahora más que nunca,{" "}
          <InlineLink link="https://coronavirus.secuoyas.com/no-te-confies">
            #noteconfies
          </InlineLink>{" "}
          Parar el virus depende de ti.
        </ModCovidHighlight>

        <ModText>
          Por otra parte, ya puedes descargar en tu móvil la app para consultar
          toda la información de las medidas de <strong>Escudo Social</strong>{" "}
          del Gobierno para garantizar la protección social y laboral en esta
          crisis sanitaria.
        </ModText>

        <Flex width={1} my="m" flexWrap="wrap" justifyContent="center">
          <Box pr="8px">
            <a href="https://apple.co/37NDAdN">
              <Box
                height="56px"
                width="167px"
                mt="12px"
                as="img"
                alt="Disponible en Apple App Store"
                src="/images/svg/Download_on_the_App_Store_Badge.svg"
              />
            </a>
          </Box>
          <Box>
            <a href="https://play.google.com/store/apps/details?id=dtic.vpsocial.escudosocial&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <Box
                height="80px"
                width="auto"
                ml="-12px"
                as="img"
                alt="Disponible en Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/es_badge_web_generic.png"
              />
            </a>
          </Box>
        </Flex>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day100;
